

import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Button from '../components/Button';
import Layout from '../components/layout/Layout';


import { Link } from 'gatsby';




import LeadForm from '../components/LeadForm';
import NewsletterSub from '../components/NewsletterSub';

const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/export-es/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/products`,

  },


];

const seo={
  title: "Soluciones por industria",
  desc:  "En Unilimpio estamos listos para atender cualquier necesidad que requiera su industria, contamos con asesores profesionales especializados en cada sector y con el más amplio portafolio de productos de limpieza para brindarle la solución más adecuada.",
  image: "segmentos.jpg",
  pathname: "/soluciones-por-sector/",
}

const Soluciones = ({location}) => (



  <Layout location={location} crumbLabel="Soluciones por Sector"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>




    



    <StaticImage src="../images/segmentos.jpg"
                className="object-contain"
                alt="Soluciones de productos diseñadas para industrias con necesidades específicas, las soluciones de Unilimpio se componen de un portafolio especializado y de servicios complementarios que le brindarán una solucion integral"
                placeholder="blurred"
                layout="fullWidth"
                imgClassName=""
                 />



<section className="bg-white flex justify-center mb-0">
  <div className="max-w-7xl mx-10 py-16 px-4 sm:px-6 lg:pt-14 pb-2 lg:px-8">
    <div className="max-w-3xl mx-auto text-center">
      <h1 className="text-5xl font-semibold text-primary-lighter ">Soluciones por Industria</h1>
      <p className="mt-4 text-xl text-blue-900 mb-20">En Unilimpio estamos listos para atender cualquier necesidad que requiera su industria, contamos con asesores profesionales especializados en cada sector y con el más amplio portafolio de limpieza para brindarle la solución más adecuada.   </p>
    </div>
  </div>
</section>


<section className="mt-0 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4  mx-6 ">
  <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div className="flex-1 flex flex-col p-8">
    <StaticImage src="../images/Alto-trafico.jpg"
                    className="flex justify-center mx-6"
                    alt="Misión de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"
        />
      <Link to="/soluciones-por-sector/alto-trafico" className="mt-6 text-lime-500 text-2xl font-bold ">Alto Tráfico</Link><br/>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Title</dt>
        <dd className="text-blue-900 text-lg text-left">Soluciones óptimas para mantener espacios de uso público totalmente desinfectados y sanitizados. Nuestros productos son altamente concentrados lo que le permite generar ahorro. Entrenamos y capacitamos a su personal de limpieza en el manejo adecuado de soluciones químicas para evitar el desperdicio.</dd>
        <Link to="/soluciones-por-sector/alto-trafico" className="text-lg text-left mt-4 text-primary-lighter inline-flex items-center  text-left">Conocer más
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </Link>
      </dl>
    </div>
  </li>

  <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div className="flex-1 flex flex-col p-8">
    <StaticImage src="../images/industria-alimenticia.jpg"
                    className="flex justify-center mx-6"
                    alt="Visión de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"
        />
      <Link  to="/soluciones-por-sector/industria-alimenticia"  className="mt-6 text-lime-500 text-2xl font-bold">Industria Alimenticia</Link><br/>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Title</dt>
        <dd className="text-blue-900 text-lg text-left">Contamos productos concentrados y sistemas de dosificación y dispensación de vanguardia que le permiten ahorrar entre un 15% y un 30% en su gasto de productos higiénicos. Cumplimos con todas las normas y certificaciones. </dd>
        <Link to="/soluciones-por-sector/industria-alimenticia" className="text-lg text-left mt-4 text-primary-lighter inline-flex items-center  text-left">Conocer más
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </Link>
      </dl>
    </div>

  </li>

  <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div className="flex-1 flex flex-col p-8">
    <StaticImage src="../images/Educacion.jpg"
                    className="flex justify-center mx-6"
                    alt="Valores corporativos de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"
        />
      <Link to="/soluciones-por-sector/educacion" className="mt-6 text-lime-500 text-2xl font-bold">Educación</Link><br/>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Title</dt>
        <dd className="text-blue-900 text-lg text-left">Contamos con las mejores soluciones de higiene profesional e higiene de manos para lograr las más altas medidas de seguridad para el estudiante y el cuerpo docente con espacios educativos libres de virus y bacterias.</dd>
        <Link to="/soluciones-por-sector/educacion" className="text-lg text-left mt-4 text-primary-lighter inline-flex items-center  text-left ">Conocer más
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </Link>
      </dl>
    </div>
  </li>

  <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div className="flex-1 flex flex-col p-8">
    <StaticImage src="../images/Servicio-de-limpieza.jpg"
                    className="flex justify-center mx-6"
                    alt="Valores corporativos de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"
        />
      <Link to="/soluciones-por-sector/servicios-de-limpieza" className="mt-6 text-lime-500 text-2xl font-bold">Servicio de Limpieza</Link><br/>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Title</dt>
        <dd className="text-blue-900 text-lg text-left">Todos nuestros productos cumplen con los requisitos de calidad y seguridad. Son productos biodegradables y concentrados desarrollados con un enfoque de máximo rendimiento para la reducción de costo y mayor durabilidad. </dd>
        <Link to="/soluciones-por-sector/servicios-de-limpieza" className="text-lg text-left mt-4 text-primary-lighter inline-flex items-center  text-left ">Conocer más
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </Link>
      </dl>
    </div>
  </li>

</section>





<div className="w-full mx-auto mb-10 mt-8">
      <Link to="/productos">
        <Button className="flex mx-auto mt-16 text-white bg-primary-lighter border-0 py-2 px-8 focus:outline-none hover:bg-lime-500 rounded text-lg">Descubrir portafolio </Button>
      </Link>
    </div>



<LeadForm/>
<NewsletterSub/>


  </Layout>
);
export default Soluciones;
